import { LogoColor } from '@videoblocks/storywind';

export const config = {
    name: 'Storyblocks logo',
    inputs: [
        {
            name: 'color',
            defaultValue: LogoColor.Light,
            enum: [
                {
                    label: 'Light',
                    value: LogoColor.Light,
                },
                {
                    label: 'Dark',
                    value: LogoColor.Dark,
                },
                {
                    label: 'Transparent Light',
                    value: LogoColor.TransparentLight,
                },
                {
                    label: 'Transparent Dark',
                    value: LogoColor.TransparentDark,
                },
            ],
        },
    ],
};
